import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CustomArrow = ({ onClick, direction }) => {
    return (
      <div
        onClick={onClick}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '24px',  // Adjust the size here
          height: '24px', // Adjust the size here
          backgroundColor: "rgb(33 30 30 / 53%)",
          borderRadius: '50%',
          cursor: 'pointer',
        
          color:"white",
          position: 'absolute',
          top: '50%',
          [direction === 'left' ? 'left' : 'right']: '10px',
          transform: 'translateY(-50%)',
          zIndex: 2,
        }}
      >
        {direction === 'left' ? '<' : '>'}
      </div>
    );
  };
function EventCarousel({events , setEventName , GetEventId}) {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 6,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 4,
        },
      };
  return (
    <section className="sponsor-logos wf100 d-block d-md-none ">
    <div className="container-fluid">
      <Carousel   
  responsive={responsive}
  renderButtonGroupOutside={true}
  customLeftArrow={<CustomArrow direction="left" />}
  customRightArrow={<CustomArrow direction="right" />}>
        {events?.length > 0 &&
          events?.map((item, index) => (
            <>
              <div key={index} >
                <a
                  href="#"
                  onClick={() => {
                    GetEventId(item);
                    setEventName(item.name);
                  }}
                >
                  <div>
                    <img src={item?.image} className="px-3"/>
                    <div
                      style={{
                        fontSize: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      className='p-1'
                    >
                    <abbr  className='p-1' title={item?.name} style={{ textDecoration:"none" }}> {item?.name}</abbr> 
                    </div>
                  </div>
                </a>
              </div>
            </>
          ))}
      </Carousel>
    </div>
  </section>
  )
}

export default EventCarousel