import React, { useEffect, useState, useRef } from "react";
import Footer from "../component/Footer";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBars,
  faCalendarAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
import { ThreeCircles } from "react-loader-spinner";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import logo from "../images/logo.jpeg";
import banner from "../images/banner.png";
import logo1 from "../images/logo3.jpeg";
import Offcanvas from "react-bootstrap/Offcanvas";
import LazyLoad from "react-lazyload";
import LoadingSpinner from "./LoadingSpinner";
import EventCarousel from "../component/EventCarousel";
function Home() {
  const [imgSrc, setImgSrc] = useState(logo);
  const [show, setShow] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [show1, setShow1] = useState(false);
  const { slug } = useParams();
  const { state } = useLocation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [events, setEvents] = useState([]);
  const [eventName, setEventName] = useState([]);
  const [eventId, setEventId] = useState(state?.eventId);
  const [eventDetail, setEventDetail] = useState({});
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState([]);
  const [expandedPostId, setExpandedPostId] = useState(null);

  const toggleExpand = (id) => {
    if (expandedPostId === id) {
      setExpandedPostId(null);
    } else {
      setExpandedPostId(id);
    }
  };

  const EventData = () => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}getEvent`,
        {},
        {
          headers: {
            "Content-Type": "Application/json",

            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setEvents(response.data.data);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const PostData = async () => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}getPosts`,
        {},
        {
          headers: {
            "Content-Type": "Application/json",

            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setPosts(response.data.data);
        } else {
          setLoading(false);
          if (response.data.error_code == 461) {
            localStorage.removeItem("token");
            window.location.href = "/login";
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const EventDetailData = async () => {
    axios
      .post(
        `${BASE_URL}getEventDetailById/${eventId}`,
        {},
        {
          headers: {
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setEventDetail(response.data.data);
          console.log(response.data.data, 1111);
        } else {
          setEventDetail({});
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    EventData();
    PostData();
    console.log(events);
    setEventDetail({});
  }, []);
  useEffect(() => {
    console.log(window.scrollY);
  }, [window.scrollY]);

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const GetEventId = (item) => {
    if (item) {
      window.scrollTo(0, 0);
      setEventId(item?._id);
      setShow(false);
    }
  };
  useEffect(() => {
    if (eventId) {
      EventDetailData();
    }
    console.log("satte", state);
  }, [eventId, state?.eventId]);

  const handleImageLoaded = () => {
    setLoading2(false);
  };



  return (
    <div class="">
      {loading ? <LoadingSpinner /> : ""}
      <div className="mainHeader ">
        <div className="w-100">
          {/* <Menu /> */}
          {/* menu  */}
          <div className="topbar pb-0 d-md-block d-none">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <ul className="topsocial">
                    <li>
                      <a href="#" className="fb">
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="tw">
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="insta">
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="in">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="yt">
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-md-6 col-sm-6 text-end"
                  style={{ color: "#efefef" }}
                >
                  <a
                    href="tel:+918871284147"
                    className=""
                    style={{ color: "#efefef" }}
                  >
                    <FontAwesomeIcon icon={faPhone} /> +91 887-1284-147
                  </a>
                </div>
              </div>
            </div>
            <div className="bg-white d-md-block d-none ">
              <div className="row w-100">
                <div className="col-3">
                  <div className="logo text-center ">
                    <Link to={"/"}>
                      <img
                        src={logo}
                        className="img-fluid "
                        style={{
                          borderRadius: "50%",
                          width: "85px",
                          height: "85px",
                        }}
                        alt
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <img
                    src={banner}
                    style={{ height: "100px", objectFit: "contain" }}
                    className="w-100 img-fluid"
                  />
                </div>
                <div className="col-3">
                  <div className="logo text-center">
                    <Link to={"/"}>
                      <img
                        src={logo1}
                        className="img-fluid"
                        style={{
                          borderRadius: "50%",
                          width: "85px",
                          height: "85px",
                        }}
                        alt
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <header id="main-header" className="main-header ">
            {/*Logo + Navbar Start*/}
            <div
              className="logo-navbar d-none d-md-block  "
              style={{ background: "#20273f" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-2 col-sm-5 d-md-none d-block">
                    <div className="logo">
                      <Link to={"/"}>
                        <img
                          src={logo}
                          className="img-fluid"
                          style={{
                            borderRadius: "50%",
                            width: "85px",
                            height: "85px",
                          }}
                          alt
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-7 d-flex justify-content-between align-items-center">
                    <nav className="main-nav">
                      <ul>
                        <li className="nav-item ">
                          <Link to={"/"}>Home</Link>
                        </li>
                        <li className="nav-item ">
                          <Link to={"/about-us"}>About Us</Link>
                        </li>
                        <li className="nav-item drop-down">
                          <a href="#">Gallery</a>
                          <ul>
                            <li>
                              <Link to={"/image-gallery"}>Image Gallery</Link>
                            </li>
                            <li>
                              <Link to={"/video-gallery"}>Vedio Gallery</Link>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item ">
                          <Link to={"/contact-us"}>Contact Us</Link>
                        </li>
                       
                      </ul>
                    </nav>
                    <button
                      className="register-now-btn"
                      onClick={() => navigate("/registration-form")}
                    >
                      Register Now
                    </button>
                  </div>
                  <button
                    className=" d-none"
                    style={{
                      width: "50px",
                      background: "transparent",
                      color: "#fff",
                      fontSize: " 25px",
                    }}
                    onClick={() => setShow1(true)}
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </button>
                </div>
              </div>
            </div>

            {/* mobile view  */}
            <div
              className="logo-navbar  d-block d-md-none "
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div
                      className="d-flex justify-content-center align-item-center "
                      style={{
                        height: "80px",
                        alignItems: "center",
                        fontSize: "25px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBars}
                        onClick={() => setShow(true)}
                      />
                    </div>
                  </div>
                  <div className="col text-center">
                    <div className="logo py-2">
                      <Link to={"/"}>
                        <img
                          src={logo}
                          className="img-fluid"
                          style={{
                            borderRadius: "50%",
                            width: "85px",
                            height: "85px",
                          }}
                          alt
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="d-flex justify-content-center align-item-center "
                      style={{
                        height: "80px",
                        alignItems: "center",
                        fontSize: "25px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBars}
                        onClick={() => setShow1(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Logo + Navbar End*/}
          </header>
          {/* mobile menu  */}

          <Offcanvas
            placement={"end"}
            show={show1}
            onHide={() => setShow1(false)}
            style={{ width: "80%" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {" "}
                <img
                  src={logo}
                  className="img-fluid"
                  style={{ borderRadius: "50%", width: "85px", height: "85px" }}
                  alt
                />{" "}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex">
              <nav className="main-nav d-block ">
                <ul
                  className=""
                  style={{
                    float: "unset",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <li className="nav-item  p-2">
                    <Link
                      to={"/"}
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item  p-2">
                    <Link
                      to={"/about-us"}
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item drop-down p-2">
                    <a
                      href="#"
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      Gallery
                    </a>
                    <ul>
                      <li>
                        <Link to={"/image-gallery"}>Image Gallery</Link>
                      </li>
                      <li>
                        <Link to={"/video-gallery"}>Vedio Gallery</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item  p-2">
                    <Link
                      to={"/contact-us"}
                      style={{ textDecoration: "none", color: "unset" }}
                    >
                      Contact Us
                    </Link>
                  </li>
                 
                  <br />
                  <button
                    className="register-now-btn"
                    onClick={() => navigate("/registration-form")}
                  >
                    Register Now
                  </button>
                </ul>
              </nav>
            </Offcanvas.Body>
          </Offcanvas>

          {/* events  */}
          <Offcanvas
            placement={"start"}
            show={show}
            onHide={() => setShow(false)}
            style={{ width: "80%" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {" "}
                <img
                  src={logo}
                  className="img-fluid"
                  style={{ borderRadius: "50%", width: "85px", height: "85px" }}
                  alt
                />{" "}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <table>
                <thead>
                  <tr>
                    <th className=" p-3 fs-3">Events Name</th>
                  </tr>
                </thead>
                <tbody>
                  {events?.length > 0 &&
                    events?.map((item, index) => (
                      <tr key={index}>
                        <td className="p-3">
                          <div
                            onClick={() => {
                              GetEventId(item);
                              setEventName(item.name);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={item?.image}
                              className="img-fluid"
                              style={{
                                mixBlendMode: "darken",
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                              }}
                              alt
                            />
                            {"    "}&nbsp;&nbsp;
                            <span> {item?.name}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>

      <div className="mainContent ">
        <div className="mainSide mt-3 d-none d-md-block ">
          <div
            className="point-table-widget scrollable-column"
            style={{ maxHeight: "500px", overflowY: "scroll" }}
          >
            {loading ? (
              <div
                className="spinner-border text-primary d-flex justify-content-center align-items-center"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <table>
                <thead className="p-3">
                  <tr>
                    <th className="text-start ms-3 p-3">
                      <h5 className="m-0">Live Events </h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {events?.length > 0 &&
                    events?.map((item, index) => (
                      <tr key={index}>
                        <td className="ms-3">
                          <div
                            onClick={() => {
                              GetEventId(item);
                              setEventName(item.name);
                            }}
                            style={{ cursor: "pointer", textAlign: "start" }}
                          >
                            {loading2 && (
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "#f0f0f0", // Optional: to mimic the placeholder background
                                }}
                              >
                                <ThreeCircles
                                  height="80"
                                  width="80"
                                  radius="9"
                                  color="black"
                                  ariaLabel="three-circles-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </div>
                            )}
                            <img
                              src={item?.image}
                              className="img-fluid"
                              onLoad={handleImageLoaded}
                              style={{
                                mixBlendMode: "darken",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                objectFit: "cover",
                              }}
                              alt
                            />
                            {"    "}&nbsp;&nbsp;
                            <span className="fs-5 fw-bold"> {item?.name}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="mainRightScroll p-3">
        <EventCarousel events={events} setEventName={setEventName} GetEventId={GetEventId}/>

          <div className="news-wrap ">
            {eventDetail?.length > 0 && (
              <div className="news-list-post">
                {eventDetail ? (
                  <>
                    <div
                      className=" pb-0 "
                      style={{ backgroundColor: "#0707BD" }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="py-2 text-white">{eventName}</div>
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      {renderHTML(eventDetail[0].details)}

                      <button
                        className="register-now-btn"
                        onClick={() =>
                          navigate("/registration-form", { state: eventDetail })
                        }
                      >
                        {" "}
                        Register Now
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}

            {/*Post Start*/}

            {posts?.length > 0 &&
              posts.map((item, index) => (
                <div className="news-list-post" key={item._id}>
                  <div className="post-thumb">
                    {item?.postType === "Image" && (
                      <LazyLoad height={200} offset={100}>
                        <img
                          src={`${item?.image}`}
                          className="postImage"
                          alt=""
                          onError={imgSrc}
                        />
                      </LazyLoad>
                    )}
                    {item?.postType === "Text" && (
                      <div
                        className="postImage"
                        style={{
                          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url('${logo}')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          height: "320px",
                        }}
                      ></div>
                    )}
                    {item?.postType === "Video" && (
                      <iframe
                        width="100%"
                        height="320"
                        className="postImage"
                        src={`${item?.video}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    )}
                  </div>
                  <div
                    className={`post-txt  `}
                    style={
                      expandedPostId === item?._id
                        ? { height: "fit-content" }
                        : { height: "300px" }
                    }
                  >
                    <ul className="post-author">
                      <li>
                        <img src={item?.Event[0]?.image} alt="" />
                        <strong>{item?.Event[0]?.name} </strong>
                      </li>
                    </ul>
                    <h4>
                      <div
                        style={{
                          width: "90%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <a href="#">
                          <abbr
                            title={item?.title}
                            style={{ textDecoration: "none" }}
                          >
                            {item?.title}{" "}
                          </abbr>
                        </a>
                      </div>
                    </h4>
                    <ul className="post-meta">
                      <li>
                        <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                        {moment(item?.date).format("DD MMMM, YYYY")}
                      </li>
                    </ul>
                    <div
                      className="postContent"
                      style={
                        expandedPostId === item?._id
                          ? { display: "unset" }
                          : { display: "-webkit-box" }
                      }
                    >
                      <p>{renderHTML(item?.description)}</p>
                    </div>
                    <a
                      className="rm mt-2"
                      onClick={() => toggleExpand(item?._id)}
                    >
                      {expandedPostId === item._id ? "Read Less" : "Read More"}
                    </a>
                    &nbsp;&nbsp;
                    <a
                      className="rm mt-2"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Share <FontAwesomeIcon icon={faFacebookF} />
                    </a>{" "}
                    &nbsp;&nbsp;
                    <a
                      className="rm mt-2"
                      href={`https://api.whatsapp.com/send?text=${window.location.href}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Share <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <footer className="mainFooter">
        <Footer />
      </footer>
    </div>
  );
}

export default Home;
