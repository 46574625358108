import "react-toastify/dist/ReactToastify.css";  import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './Pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import RegistrationForm from './Pages/RegistrationForm';
import Contact from './Pages/Contact';
import ImageGallery from './Pages/ImageGallery';
import VideoGallery from './Pages/VideoGallery';
import About from './Pages/About';
import PrivacyPolicy from "./Pages/PrivacyPolicy";


function App() {

  const router = createBrowserRouter([
    {
   path:"/",
   element:<Home/>
    }
    ,{
      path:"/registration-form",
      element:<RegistrationForm/>
    }
    ,{
      path:"/contact-us",
      element:<Contact/>
    },{
      path:"/image-gallery",
      element:<ImageGallery/>
    },{
      path:"/video-gallery",
      element:<VideoGallery/>
    },{
      path:"/about-us",
      element:<About/>
    },,{
      path:"/privacy-policy",
      element:<PrivacyPolicy/>
    }
   
  ])


  return (
<>
<ToastContainer position="bottom-right" />
<RouterProvider router={router} />
</>
  );
}

export default App;
