import React from 'react'
import Layout from '../component/Layout'

function About() {
  return (
  <>
  <Layout>
  <div className="team wf100 pt-4">
  {/*Start*/}
  <div className="player-squad">
    <div className="container">
      <div className="row">
        {/*Squad Start*/}
        <div className="col-lg-8">
          {/*Player Box Start*/}
          {/*Player Box End*/}
          {/*Player Biography Start*/}
          <div className="player-bio">
            <h4>ABOUT HABS LIVE</h4>
            <div className="txt">
              <p>I am Vishnu Pal Founder of HABS LIVE INDIA </p>
              <blockquote>HABS LIVE INDIA is an event management firm operated from Gwalior Madhya Pradesh.</blockquote>
              <p>1. We have been organizing and promoting sports events for the last ten years.</p>
              <p>2. The objectives and efforts of the based on three points.</p>
              <p>3. To give fresh new opportunities to talented players Connecting sponsors with every person and every field Development of basic facilities for sports and players.</p>
              <p>4. Right now we are going to start our local to global event from all the districts in of the country.</p>
              <p>5. Local to Global Event means District Level Event / State Level Event / National Event / International Event.</p>
              <p>6. It is our mission and endeavor to give chance / platform / mission to all the talent of the country through local to global event.</p>
              <p>7. District level program will be organized in all the districts of the country through Local to Global event. In the district level program to be held in all the districts of the country.</p>
              <p>8. Any person / team / village team / school team / colleges team / university team / or any other institution's team can participate.</p>
              <p>9. This is the only program of the country and the world that will be organized simultaneously in all the districts of the country.</p>
              <p>10. It is the only program in the country and the world that provides the platform / chance / mission from the district level to the international level together to the common people. </p>
              <p>11. Right now we are going to organize many sport programs (365).</p>
              <p>12. HABS LIVE INDIA is going to recruit staff for the following sports series.</p>
              <p>13. Master blaster cricket series is going to organize national level cricket tournament for all the (800+) districts of the country.</p>
              <p>14. Any sports players who want to participate in these events can contact us.</p>
            </div>
          </div>
          {/*Player Biography End*/}
        </div>
        {/*Squad End*/}
        {/*Sidebar Start*/}
        <div className="col-lg-4">
          <div className="sidebar">
            <div className="widget">
              <h4>Cricket</h4>
              <div className="last-match-widget">
                <p><strong>To become a good player, you need talent.</strong></p>
                <ul className="match-teams-vs">
                  <li className="team-logo"><img src="images/lp-img1.jpg" alt /></li>
                  <li className="mvs" />
                </ul>
              </div>
            </div>
            <div className="widget">
              <h4>Football</h4>
              <div className="last-match-widget">
                <p><strong>I always want more. Whether it's a goal or winning a game.</strong></p>
                <ul className="match-teams-vs">
                  <li className="team-logo"><img src="images/lp-img2.jpg" alt /></li>
                  <li className="mvs" />
                </ul>
              </div>
            </div>
            {/*widget end*/}
          </div>
        </div>
        {/*Sidebar End*/}
      </div>
    </div>
  </div>
  {/*End*/}
</div>
  </Layout>

{/*team Page End*/}

  </>
  )
}

export default About