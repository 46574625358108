import React, { useEffect, useState } from "react";
import Layout from "../component/Layout";
import axios from "axios";
import { toast } from "react-toastify";
const initialState = {
  name: "",
  email: "",
  mobile_no: "",
  subject: "",
  message: "",
};
function Contact() {
  const [values, setValues] = useState(initialState);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const AddContact = () => {
    if(!values.name){
      toast.error("Name is required!");
      return;
    }
    if(!(/^\d{10}$/.test(values.mobile_no))){
      toast.error("Enter Valid Mobile Number");
      return;
    }
    let obj = {
      name: values.name,
      email: values.email,
      mobile_no: values.mobile_no,
      subject: values.subject,
      message: values.message,
    };
    axios
      .post(`${BASE_URL}addContact`, obj, {
        headers: {
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success("Information Sent Successful!");
          setValues(initialState);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {}, []);
  return (
    <Layout>
      <div className="contact-two wf100 p-4">
        <div className="container">
          <div className="row">
            {/*Form Start*/}
            <div className="col-lg-7">
              <div className="contact-form">
                <h2>Contact Us Now</h2>
                <ul className="form-row">
                  <li className="half-col">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="name"
                      value={values.name}
                      placeholder=" Name"
                    />
                  </li>
                  <li className="half-col">
                    <input
                      type="email"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="email"
                      value={values.email}
                      placeholder="Email"
                    />
                  </li>
                  <li className="half-col">
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="mobile_no"
                      value={values.mobile_no}
                      placeholder="Contact Number"
                    />
                  </li>

                  <li className="half-col">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="subject"
                      value={values.subject}
                      placeholder="Subject"
                    />
                  </li>

                  <li className="full-col">
                    <textarea
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="message"
                      value={values.message}
                      placeholder="Write Your Message"
                      defaultValue={""}
                    />
                  </li>
                  <li className="full-col">
                    <button type="button" onClick={() => AddContact()}>
                      Contact us Now
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <div class="google-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7159.152899861352!2d78.236407!3d26.210453!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3976c3d0a720ec97%3A0x5b86a8387a6fd65!2sHABSLIVE!5e0!3m2!1sen!2sin!4v1721197894230!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            {/*Form End*/}
          </div>

          <div className="row mt-60 mb-4 mt-4">
          
            {/*Start*/}
            <div className="col-md-4">
              <div className="contact-box">
                <h5>Address:</h5>
                <p> M/S HOLLYWOOD AND BOLLYWOOD SOLUTION H. N. 60K MOHANPUR, Morar Cantt., Gwalior, Madhya Pradesh 474006</p>
              </div>
            </div>
            {/*End*/}
            {/*Start*/}
            <div className="col-md-4">
              <div className="contact-box">
                <h5>Contact:</h5>
                <p>
                  <strong>Phone:</strong><a href="tel:+918871284147"> +91 887-1284-147</a>
                </p>
                <p>
                  <strong>Fax: </strong><a href="tel:+919074717401"> +91 907-4717-401</a>
                </p>
              </div>
            </div>
            {/*End*/}
            {/*Start*/}
            <div className="col-md-4">
              <div className="contact-box">
                <h5>For More Information:</h5>
                <p>
                {" "}
                <strong>Email:</strong><a href="mailto:hollywoodandbollywoodsolution@yahoo.in" style={{ wordBreak:"break-all" }}>hollywoodandbollywoodsolution@yahoo.in</a> 
                </p>
               
              </div>
            </div>
            {/*End*/}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
