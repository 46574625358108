import React from 'react'
// import Breadcrumd from "./Breadcrumb"
// import MegaMenu from './MegaMenu'
import Menu from './Menu'
import Footer from './Footer'
// import { Navigate, useNavigate } from 'react-router-dom';
// import Footer from './Footer';
// import useHeaderScroll from './UseHeaderScroll';
// import useSmoothScroll from './UseSmoothScroll';
// import Breadcrumb from './Breadcrumb';

function Layout({children}) {
    // const navigate= useNavigate();
    // const LogOut=()=>{
    //     localStorage.removeItem('token');
    //     navigate('/login');
    //   }
    // useHeaderScroll();
    // useSmoothScroll();
  return (
 
     <div>
<div className="wrapper">
 <Menu/>

{children}

  <Footer/>
    

</div>


</div>

 
  )
}

export default Layout