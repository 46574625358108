import React, { useEffect, useState } from "react";
import Layout from "../component/Layout";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
const initialState = {
  apply_for: "",
  event: "",
  name: "",
  age: "",
  gender: "",
  email: "",
  mobile_no: "",
  state: "Madhya Pradesh",
  district: "Gwalior",
  town: "",
  jerseyNo: "",
  jerseyName: "",
  jerseySize: "",
  identityId: "",
  photo: "",
  resume: "",
  feeScreenShot: "",
};
function RegistrationForm() {
  const [values, setValues] = useState(initialState);
  const [fileName1, setFileName1] = useState("No file chosen");
  const [fileName2, setFileName2] = useState("No file chosen");
  const [fileName3, setFileName3] = useState("No file chosen");
  const [fileName4, setFileName4] = useState("No file chosen");
  const [events, setEvents] = useState([]);
  const {state}=useLocation();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "identityId") {
      setFileName1(
        e.target.files[0] ? e.target.files[0].name : "No file chosen"
      );
      setValues({
        ...values,
        identityId: [...values.identityId, ...files],
      });
    } else if (name == "photo") {
      setFileName2(
        e.target.files[0] ? e.target.files[0].name : "No file chosen"
      );

      setValues({
        ...values,
        photo: [...values.photo, ...files],
      });
    } else if (name == "resume") {
      setFileName3(
        e.target.files[0] ? e.target.files[0].name : "No file chosen"
      );

      setValues({
        ...values,
        resume: [...values.resume, ...files],
      });
    } else if (name == "feeScreenShot") {
      setFileName4(
        e.target.files[0] ? e.target.files[0].name : "No file chosen"
      );

      setValues({
        ...values,
        feeScreenShot: [...values.feeScreenShot, ...files],
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };
  const EventData = () => {
   axios.post(`${BASE_URL}getEvent`,{},{
    headers:{
      'Content-Type': 'Application/json',
      
      version:'1.0.0'
  }
   }).then((response)=>{
    if(response.data.success){
      setEvents(response.data.data);
    }else{
   console.log(response.data.message);
    }
   }).catch((err)=>{
    console.log(err.message);

   })
  }
  const AddRegistration = () => {
    if(!values.apply_for){
      toast.error("Apply for field is required");
      return;
    }
    
    if(!values.event){
      toast.error("Event field is required");
      return;
    }
    if(!values.name){
      toast.error("Name field is required");
      return;
    }
    if(!values.age){
      toast.error("Age field is required");
      return;
    }
    if(!(/^\d{10}$/.test(values.mobile_no))){
      toast.error("Enter Valid Mobile Number");
      return;
    }
    const fd = new FormData();
    fd.append("apply_for", values.apply_for);
    fd.append("event", values.event);
    fd.append("name", values.name);
    fd.append("age", values.age);
    fd.append("gender", values.gender);
    fd.append("mobile_no", values.mobile_no);
    fd.append("email", values.email);
    fd.append("state", values.state);
    fd.append("district", values.district);
    fd.append("town", values.town);
    fd.append("jerseyNo", values.jerseyNo);
    fd.append("jerseyName", values.jerseyName);
    fd.append("jerseySize", values.jerseySize);
    fd.append("identityId", values.identityId[0]);
    fd.append("photo", values.photo[0]);
    fd.append("resume", values.resume[0]);
    fd.append("feeScreenShot", values.feeScreenShot[0]);
    axios
      .post(`${BASE_URL}addRegistration`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          setValues(initialState);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const stateData = () => {
    axios
      .get(`${BASE_URL}getALLState`, {
        headers: {
          "Content-Type": "Application/json",

          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setStates(response.data.data);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const DistrictData = () => {
    axios
      .get(`${BASE_URL}getAllDistrictTehsil`, {
        headers: {
          "Content-Type": "Application/json",

          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setDistricts(response.data.data);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    stateData();
    DistrictData();
    EventData();
    state&&
    setValues({...values,event:state[0]?.eventId})
    // console.log(state[0]?.eventId,222)
  }, []);
  return (
    <Layout>
      <div className="contact-two wf100 p80-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-1" />
            {/*Form Start*/}
            <div className="col-lg-10">
              <div className="contact-form">
                <h2>Register Now</h2>
                <ul className="form-row">
                  <li className="half-col">
                    <select
                      className="form-control "
                      onChange={(e) => handleInputChange(e)}
                      name="apply_for"
                      value={values.apply_for}
                    >
                      <option value="" >--Apply For--</option>

                      <option value="Staff">Staff</option>
                      <option value="Tournament">Tournament</option>
                    </select>
                  </li>
                  <li className="half-col">
                    <select
                      className="form-control "
                      onChange={(e) => handleInputChange(e)}
                      name="event"
                      value={values.event}
                    >
                      <option value="">--Select Event--</option>
                      {events?.length > 0 &&
                        events?.map((item) => (
                          <option value={item?._id} key={item?._id}>
                            {item?.name}
                          </option>
                        ))}
                    </select>
                  </li>
                  <li className="half-col">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="name"
                      value={values.name}
                      placeholder="Candidate's Name"
                    />
                  </li>
                  <li className="half-col">
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="age"
                      value={values.age}
                      placeholder="Candidate's Age"
                    />
                  </li>
                  <li className="half-col">
                    <select
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="gender"
                      value={values.gender}
                    >
                      <option value="">--Candidate's Gender--</option>

                      <option value="Male">Male</option>
                      <option value="Female" >Female</option>
                    </select>
                  </li>
                  <li className="half-col">
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="mobile_no"
                      value={values.mobile_no}
                      placeholder="Contact Number"
                    />
                  </li>
                  <li className="half-col">
                    <input
                      type="email"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="email"
                      value={values.email}
                      placeholder="Enter  Email Id"
                    />
                  </li>
                  <li className="half-col">
                    <select
                      className="form-control "
                      onChange={(e) => handleInputChange(e)}
                      name="state"
                      value={values.state}
                    >
                      <option value="">--select state--</option>

                      {states?.map((state) => (
                        <option value={state.name} key={state.key}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </li>
                  {values.state == "Madhya Pradesh" ? (
                    <li className="half-col">
                      <select
                        className="form-control "
                        onChange={(e) => handleInputChange(e)}
                        name="district"
                        value={values.district}
                      >
                        <option value="">--select district--</option>

                        {districts?.map((item) => (
                          <option key={item.district} value={item.district}>
                            {item.district}
                          </option>
                        ))}
                      </select>
                    </li>
                  ) : (
                    <li className="half-col">
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => handleInputChange(e)}
                        name="district"
                        value={values.district}
                        placeholder="Enter District"
                      />
                    </li>
                  )}

                  <li className="half-col">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="town"
                      value={values.town}
                      placeholder="Enter town"
                    />
                  </li>
                  <li className="half-col">
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="jerseyNo"
                      value={values.jerseyNo}
                      placeholder="Enter  jersey No."
                    />
                  </li>
                  <li className="half-col">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="jerseyName"
                      value={values.jerseyName}
                      placeholder="Enter jersey Name"
                    />
                  </li>
                  <li className="half-col">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => handleInputChange(e)}
                      name="jerseySize"
                      value={values.jerseySize}
                      placeholder="Enter jersey Size"
                    />
                  </li>
                  <li className="half-col mb-3">
                    <div className="file-upload">
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => handleInputChange(e)}
                        name="identityId"
                      />
                      <label htmlFor="file">Choose an Identity</label>
                      <span className="file-name">{fileName1}</span>
                    </div>
                  </li>

                  <li className="half-col mb-3">
                    <div className="file-upload">
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => handleInputChange(e)}
                        name="photo"
                      />
                      <label htmlFor="file">Choose your Photo</label>
                      <span className="file-name">{fileName2}</span>
                    </div>
                  </li>

                  <li className="half-col mb-3">
                    <div className="file-upload">
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => handleInputChange(e)}
                        name="resume"
                      />
                      <label htmlFor="file">Choose resume file</label>
                      <span className="file-name">{fileName3}</span>
                    </div>
                  </li>

                  <li className="half-col mb-3">
                    <div className="file-upload">
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => handleInputChange(e)}
                        name="feeScreenShot"
                      />
                      <label htmlFor="file">Choose fee screenshot</label>
                      <span className="file-name">{fileName4}</span>
                    </div>
                  </li>
                  <li className="full-col">
                    <button type="button" onClick={()=>AddRegistration()}>Submit</button>
                  </li>
                </ul>
              </div>
            </div>
            {/*Form End*/}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default RegistrationForm;
