import React, { useEffect, useState } from "react";
import Layout from "../component/Layout";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faPhone,
  faLink,
  faClose
} from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function ImageGallery() {
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [showTab, setShowTab] = useState("all");
  const [popUpImg , setpopUpImg]=useState([]);
  const [imageCategoryList, setImageCategoryList] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [show, setShow] = useState(false);
  const imageData = async () => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}getImages`,
        {},
        {
          headers: {
            "Content-Type": "Application/json",
            "x-access-token": `${token}`,
            version: "1.0.0",
          },
        }
      );
      if (response.data.success) {
        setImageList(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        if (response.data.error_code == 461) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching user data:", error);
      // throw error;
    }
  };

  const imageCategoryData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}getImageCategory`,
        {},
        {
          headers: {
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      );
      if (response.data.success) {
        setImageCategoryList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // throw error;
    }
  };

  useEffect(() => {
    imageData();
    imageCategoryData();
  }, []);
  return (
    <>
      {loading ? <LoadingSpinner /> : ""}

      <Layout>
        {/*Team Section Start*/}
        <section className="wf100 h3-team">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-4">
                <div className="h3-section-title">
                  {" "}
                  {/* <strong> Gallery</strong> */}
                  <h2>Image Gallery</h2>
                </div>
              </div>
              <div className="col-md-6 col-sm-8">
                <nav>
                  <div className="nav" id="nav-tab" role="tablist">
                    <a
                      className={`nav-item nav-link ${
                        "all" == showTab ? "active" : ""
                      }`}
                      id={`nav-0-tab`}
                      data-toggle="tab"
                      href={`#nav-all`}
                      role="tab"
                      aria-controls={`nav-all`}
                      aria-selected="true"
                      onClick={() => setShowTab("all")}
                    >
                      All
                    </a>
                    {imageCategoryList.map((item, index) => (
                      <a
                        className={`nav-item nav-link ${
                          item.name == showTab ? "active" : ""
                        }`}
                        id={`nav-${index + 1}-tab`}
                        data-toggle="tab"
                        href={`#nav-${item.name}`}
                        role="tab"
                        aria-controls={`nav-${item.name}`}
                        aria-selected="true"
                        onClick={() => setShowTab(item.name)}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </nav>
              </div>
            </div>
            <div className="tab-content wf100" id="nav-tabContent">
              {imageCategoryList.map((item1, index) => (
                <div
                  key={item1._id}
                  className={`tab-pane fade ${
                    showTab == item1.name ? "show active" : ""
                  }`}
                  id={`nav-${item1.name}`}
                  role="tabpanel"
                  aria-labelledby={`nav-${index + 1}-tab`}
                >
                  <div className="row">
                    {/*Player Start*/}
                    {imageList
                      .filter((item) => item.categoryId === item1._id)
                      ?.map((item2, index) => (
                        <div
                          key={item2._id}
                          className="col-md-4 col-sm-6 col-xs-6 col-12"
                        >
                          <div className="gal-thumb">
                            {" "}<a  
                            onClick={()=>{
                              setShow(true)
                              setpopUpImg(item2.image)
                              }} data-rel="prettyPhoto[gallery1]"><FontAwesomeIcon icon={faLink} /></a>
                            <img
                              src={item2?.image}
                              style={{
                                width: "100%",
                                height: "246px",
                                objectFit: "cover",
                              }}
                              alt
                            />{" "}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}

              {showTab == "all" && (
                <div
                  className={`tab-pane fade ${
                    showTab == "all" ? "show active" : ""
                  }`}
                  id={`nav-all`}
                  role="tabpanel"
                  aria-labelledby={`nav-0-tab`}
                >
                  <div className="row">
                    {/*Player Start*/}
                    {imageList?.map((item2) => (
                      <div
                        key={item2._id}
                        className="col-md-4 col-sm-6 col-xs-6 col-12"
                      >
                        <div className="gal-thumb">
                        <a   onClick={()=>{
                              setShow(true)
                              setpopUpImg(item2.image)
                              }} data-rel="prettyPhoto[gallery1]"><FontAwesomeIcon icon={faLink} /></a>
                          <img
                            src={item2?.image}
                            style={{
                              width: "100%",
                              height: "246px",
                              objectFit: "cover",
                            }}
                            alt
                          />{" "}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        {/*Team Section End*/}
      </Layout>
      <Modal show={show} onHide={()=>setShow(false)}>
        {/* <Modal.Header closeButton>
          
        </Modal.Header> */}
        <Modal.Body className="pb-0"><img src={popUpImg} className="img-fluid"/></Modal.Body>
        <Modal.Footer className="border-0">
          <button className="btn btn-secondary rounded-5 text-uppercase fs-6 py-1" onClick={()=>setShow(false)}>close <FontAwesomeIcon icon={faClose} /></button>
       
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ImageGallery;
