// LoadingSpinner.js
import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

const LoadingSpinner = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{ height: "100vh" }}
  >
    <ThreeCircles
      height="80"
      width="80"
      radius="9"
      color="black"
      ariaLabel="three-dots-loading"
    />
  </div>
);

export default LoadingSpinner;
