import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.jpeg";
import banner from "../images/banner.png";
import logo1 from "../images/logo2.jpeg";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
function Menu() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState([]);
  const [eventDetail, setEventDetail] = useState({});
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const EventData = () => {
    axios
      .post(
        `${BASE_URL}getEvent`,
        {},
        {
          headers: {
            "Content-Type": "Application/json",

            version: "1.0.0",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setEvents(response.data.data);
        } else {
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const EventDetailData = async () => {
    axios
      .post(
        `${BASE_URL}getEventDetailById/${eventId}`,
        {},
        {
          headers: {
            version: "1.0.0",
            "Content-Type": "Application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setEventDetail(response.data.data);
        } else {
          console.log(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const GetEventId = (item) => {
    setEventId(item?._id);
    navigate("/", { state: { eventId: item._id } });
    console.log("click", item?._id);
  };
  useEffect(() => {
    EventData();
  }, []);
  useEffect(() => {
    if (eventId) {
      EventDetailData();
    }
  }, [eventId]);
  return (
    <>
      <div className="topbar pb-0 d-md-block d-none">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <ul className="topsocial">
                <li>
                  <a href="#" className="fb">
                    {" "}
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li>
                  <a href="#" className="tw">
                    {" "}
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
                <li>
                  <a href="#" className="insta">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li>
                  <a href="#" className="in">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </li>
                <li>
                  <a href="#" className="yt">
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="col-md-6 col-sm-6 text-end"
              style={{ color: "#efefef" }}
            >
              <FontAwesomeIcon icon={faPhone} /> +91 088-7128-4147
            </div>
          </div>
        </div>
        <div className="bg-white d-md-block d-none ">
          <div className="row w-100">
            <div className="col-3">
              <div className="logo text-center">
                <Link to={"/"}>
                  <img
                    src={logo}
                    className="img-fluid"
                    style={{
                      borderRadius: "50%",
                      width: "85px",
                      height: "85px",
                    }}
                    alt
                  />
                </Link>
              </div>
            </div>
            <div className="col-6">
              <img
                src={banner}
                style={{ height: "100px", objectFit: "contain" }}
                className="w-100 img-fluid"
              />
            </div>
            <div className="col-3">
              <div className="logo text-center">
                <Link to={"/"}>
                  <img
                    src={logo1}
                    className="img-fluid"
                    style={{
                      borderRadius: "50%",
                      width: "85px",
                      height: "85px",
                    }}
                    alt
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header id="main-header" className="main-header ">
        {/*Logo + Navbar Start*/}
        <div
          className="logo-navbar d-none d-md-block  "
          style={{ background: "#20273f" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-5 d-md-none d-block">
                <div className="logo">
                  <Link to={"/"}>
                    <img
                      src={logo}
                      className="img-fluid"
                      style={{
                        borderRadius: "50%",
                        width: "85px",
                        height: "85px",
                      }}
                      alt
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-12 col-sm-7 d-flex justify-content-between align-items-center">
                <nav className="main-nav">
                  <ul>
                    <li className="nav-item ">
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li className="nav-item ">
                      <Link to={"/about-us"}>About Us</Link>
                    </li>
                    <li className="nav-item drop-down">
                      <a href="#">Gallery</a>
                      <ul>
                        <li>
                          <Link to={"/image-gallery"}>Image Gallery</Link>
                        </li>
                        <li>
                          <Link to={"/video-gallery"}>Vedio Gallery</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item ">
                      <Link to={"/contact-us"}>Contact Us</Link>
                    </li>
                  
                  </ul>
                </nav>

                <button className="register-now-btn" onClick={()=>navigate("/registration-form")}>Register Now</button>
              </div>
              {/* <button className=' d-lg-none' style={{     width: "50px",
    background: "transparent",    color: "#fff",
    fontSize:" 25px" }} onClick={()=>setShow1(true)} >
          <i className='fa fa-bars'></i>
        </button> */}
            </div>
          </div>
        </div>

        {/* mobile view  */}
        <div
          className="logo-navbar  d-block d-md-none "
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div
                  className="d-flex justify-content-center align-item-center "
                  style={{
                    height: "80px",
                    alignItems: "center",
                    fontSize: "25px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBars}
                    onClick={() => setShow(true)}
                  />
                </div>
              </div>
              <div className="col py-2 ">
                <div className="logo text-center">
                  <Link to={"/"}>
                    <img
                      src={logo}
                      className="img-fluid"
                      style={{
                        borderRadius: "50%",
                        width: "85px",
                        height: "85px",
                      }}
                      alt
                    />
                  </Link>
                </div>
              </div>
              <div className="col">
                <div
                  className="d-flex justify-content-center align-item-center "
                  style={{
                    height: "80px",
                    alignItems: "center",
                    fontSize: "25px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBars}
                    onClick={() => setShow1(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Logo + Navbar End*/}
      </header>
      {/* mobile menu  */}

      <Offcanvas
        placement={"end"}
        show={show1}
        onHide={() => setShow1(false)}
        style={{ width: "80%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {" "}
            <img
              src={logo}
              className="img-fluid"
              style={{ borderRadius: "50%", width: "85px", height: "85px" }}
              alt
            />{" "}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex">
          <nav className="main-nav d-block ">
            <ul
              className=""
              style={{
                float: "unset",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <li className="nav-item  p-2">
                <Link
                  to={"/"}
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item  p-2">
                <Link
                  to={"/about-us"}
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item drop-down p-2">
                <a href="#" style={{ textDecoration: "none", color: "unset" }}>
                  Gallery
                </a>
                <ul>
                  <li>
                    <Link to={"/image-gallery"}>Image Gallery</Link>
                  </li>
                  <li>
                    <Link to={"/video-gallery"}>Vedio Gallery</Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item  p-2">
                <Link
                  to={"/contact-us"}
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  Contact Us
                </Link>
              </li>
              
              <br />
              <button className="register-now-btn" onClick={()=>navigate("/registration-form")}>Register Now</button>
            </ul>
          </nav>
        </Offcanvas.Body>
      </Offcanvas>

      {/* events  */}
      <Offcanvas
        placement={"start"}
        show={show}
        onHide={() => setShow(false)}
        style={{ width: "80%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {" "}
            <img
              src={logo}
              className="img-fluid"
              style={{ borderRadius: "50%", width: "85px", height: "85px" }}
              alt
            />{" "}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <table>
            <thead>
              <tr>
                <th className=" p-3 fs-3">Events</th>
              </tr>
            </thead>
            <tbody>
              {events?.length > 0 &&
                events?.map((item, index) => (
                  <tr key={index}>
                    <td className="p-3">
                      <div
                        onClick={() => GetEventId(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={item?.image}
                          className="img-fluid"
                          style={{
                            mixBlendMode: "darken",
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                          }}
                          alt
                        />
                        {"    "}&nbsp;&nbsp;
                        <span> {item?.name}</span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Menu;
