import React, { useEffect, useState } from "react";
import Layout from "../component/Layout";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";

function VideoGallery() {
  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const VideoData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}getVideo`,
        {},
        {
          headers: {
            "Content-Type": "Application/json",
            version: "1.0.0",
          },
        }
      );
      if (response.data.success) {
        setVideoList(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        if (response.data.error_code == 461) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching user data:", error.message);
      // throw error;
    }
  };

  useEffect(() => {
    VideoData();
  }, []);
  return (
    <>
   
    {loading ? <LoadingSpinner /> : ""}
    <Layout>
       
      <div className="image-gallery gallery  p80">
     
        <div className="container">
          <div className="row pt-4">
            <div className="col-12 ">
              <div className="h3-section-title">
                {" "}
                {/* <strong> Gallery</strong> */}
                <h2>Video Gallery</h2>
              </div>
            </div>
            {videoList?.length > 0
              ? videoList?.map((item, index) => (
                  <div className="col-sm-4" key={index}>
                    <iframe
                      // style={{width:"100%",height:"246px"}}
                      src={item?.video}
                      title="YouTube video player"
                      frameBorder={0}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      loading="lazy"
                      allowFullScreen
                    ></iframe>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
}

export default VideoGallery;
