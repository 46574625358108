import React from 'react'
import Layout from '../component/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

function PrivacyPolicy() {
  return (
  <>
  <Layout>
  <div className="team wf100 pt-4">
  {/*Start*/}
  <div className="player-squad">
    <div className="container">
      <div className="row">
        {/*Squad Start*/}
        <div className="col-lg-12">
          {/*Player Box Start*/}
          {/*Player Box End*/}
          {/*Player Biography Start*/}
          <div className="player-bio">
            <h4>Privacy Policy</h4>
            <div className="txt">
              <p>Welcome to HABS LIVE INDIA. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you interact with our website and services.</p>
              <blockquote>Information We Collect</blockquote>
              <p>Personal Information: We may collect personal information such as your name, email address, phone number, and other contact details when you register for events. contact us directly.</p>
              <p>Usage Data: We may collect information about how you access and use our website, including your IP address, browser type, device information, and browsing behavior.</p>
              <blockquote>How We Use Your Information</blockquote>
              <p>We use your information for the following purposes:</p>
              <p>1. To manage and organize sports events.</p>
              <p>2. To communicate with you regarding event details, updates, and promotions.</p>
              <p>3. To improve our website and services.</p>
              <p>4. To comply with legal obligations</p>
              <blockquote>Sharing Your Information</blockquote>
              <p>We do not share your personal information with third parties, except:</p>
              <p>1. When required by law or to protect our legal rights.</p>
              <p>2. With service providers who assist us in operating our website and services, under strict confidentiality agreements.</p>
              <blockquote>Data Security</blockquote>
              <p>We take appropriate measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.</p>
              <blockquote> Your Rights</blockquote>
              <p>You have the right to:</p>
              <p>1. Access the personal information we hold about you.</p>
              <p>2. Request corrections to inaccurate or incomplete data</p>
              <p>3. Request the deletion of your personal information.</p>
              <blockquote>Changes to This Privacy Policy</blockquote>
              <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated date.</p>
              <blockquote>Contact Us</blockquote>
              <p>If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us at:</p>
              <blockquote>HABS LIVE INDIA  <br/><a href="mailto:hollywoodandbollywoodsolution@yahoo.in" style={{ wordBreak:"break-all" }}>hollywoodandbollywoodsolution@yahoo.in</a> <br/> <a href="tel:+918871284147" className='' style={{ color:"#99a1b4" }}><FontAwesomeIcon icon={faPhone} /> +91 887-1284-147</a></blockquote>

            </div>
          </div>
          {/*Player Biography End*/}
        </div>
   
      </div>
    </div>
  </div>
  {/*End*/}
</div>
  </Layout>

{/*team Page End*/}

  </>
  )
}

export default PrivacyPolicy