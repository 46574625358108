import React from 'react'
import logo from "../images/logo.jpeg";
// import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight , faMapMarkerAlt, faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
  const navigate=useNavigate()
  return (
<footer className="wf100 main-footer">
  <div className="container">
    <div className="row">
      {/*Footer Widget Start*/}
      <div className="col-lg-3 col-md-6">
        <div className="footer-widget about-widget">
          <img src={logo} className='img-fluid' style={{ width:"100px",borderRadius:"50%" }}  onClick={()=>navigate("/")} />
          <p> HABS LIVE INDIA is an event management firm operated from Gwalior Madhya Pradesh. </p>
        
        </div>
      </div>
      {/*Footer Widget End*/} 
      {/*Footer Widget Start*/}
      <div className="col-lg-3 col-md-6">
        <div className="footer-widget">
          <h4>About </h4>
          <ul className="footer-links">
            <li><a href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Organizing</a></li>
            <li><a href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> Promoting</a></li>
            <li><a href="#"><FontAwesomeIcon icon={faAngleDoubleRight}/> Programming</a></li>
            <li><a href="#"><FontAwesomeIcon icon={faAngleDoubleRight}/> Developing</a></li>
            <li><a href="#"><FontAwesomeIcon icon={faAngleDoubleRight}/> Employment</a></li>
            <li><a href="#"><FontAwesomeIcon icon={faAngleDoubleRight}/>Education</a></li>
            <li><a href="#"><FontAwesomeIcon icon={faAngleDoubleRight}/>Event</a></li>
            <li><a href="#"><FontAwesomeIcon icon={faAngleDoubleRight}/> Entertainment</a></li>
            <li><a href="#"><FontAwesomeIcon icon={faAngleDoubleRight}/> Habs live india</a></li>

          </ul>
        </div>
      </div>

      <div className="col-lg-2 col-md-6">
        <div className="footer-widget">
          <h4>Useful Links </h4>
          <div className=" about-widget">
          <ul className="footer-widget">
            <li ><Link to={"/"}><FontAwesomeIcon icon={faAngleDoubleRight} /> Home</Link></li>
            <li><Link to={"/about-us"}><FontAwesomeIcon icon={faAngleDoubleRight} /> About Us</Link></li>
            <li><Link to={"/contact-us"}><FontAwesomeIcon icon={faAngleDoubleRight}/> Contact Us</Link></li>
            <li><Link to={"/privacy-policy"}><FontAwesomeIcon icon={faAngleDoubleRight}/> Privacy Policy</Link></li>
           

          </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="footer-widget">
          <h4>Contact Info </h4>
          <div className=" about-widget">
          <address>
            <ul>
              <li><FontAwesomeIcon icon={faMapMarkerAlt} className="text-uppercase" /> {"  "}HN-42 Manik Vilas Near Hotel Land Mark gwalior</li>
              <li><a href="tel:+918871284147" className='' style={{ color:"#99a1b4" }}><FontAwesomeIcon icon={faPhone} /> +91 887-1284-147</a> </li>
              <li><FontAwesomeIcon icon={faEnvelope} />hoollywoodandbollywoodsolution@yahoo.in
               
              </li>
            </ul>
          </address>
        </div>
        </div>
      </div>
     
    </div>
  </div>
  <div className="container brtop">
    <div className="row">
      <div className="col-lg-6 col-md-6">
        <p className="copyr"> All Rights Reserved of habslive © 2024,
           {/* Developed By: <a href="https://itsgwalior.com/" target='blank'  className='text-white'>ITSGWALIOR</a>  */}
           </p>
      </div>
    
    </div>
  </div>
</footer>

  )
}

export default Footer